import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class AboutPage extends Component {
  // constructor(props){
  //   super(props);
  //   this.state = {ready: false}
  // }

  // componentDidMount(){
  //     if(window.cloudinary){ 
  //       const myWidget = window.cloudinary.galleryWidget({
  //         "container": "#gallery",
  //         "cloudName": "barkandbreakfast",
  //         "displayProps": {
  //             "mode": "expanded",
  //             "columns": 2
  //         },
  //         "mediaAssets": [{
  //             "tag": "bark",
  //             "mediaType": "image"
  //         }],
  //         "zoomProps": {
  //             "type": "popup",
  //             "steps": 1,
  //             "level": 1
  //         }
  //       });
  //       myWidget.render();
  //       this.setState({ready: true});
        
  //       console.log("Did the thing")
  //     }
  // }
  render() {
    return (
      <Layout page={"pictures"}>
        <div className="main">
          <h1 className="title">Our Home</h1>
          <div className="text">Our home is fully prepared for your pet's stay, set on a quiet one-acre lot in Milan Michigan.</div>
          <div id="gallery"></div>
          <img className="contentImage" src="https://res.cloudinary.com/barkandbreakfast/image/upload/f_auto,q_auto/v1608506619/home1.jpg" alt=""/>
          <img className="contentImage" src="https://res.cloudinary.com/barkandbreakfast/image/upload/f_auto,q_auto/v1608506619/home2.jpg" alt=""/>
          <img className="contentImage" src="https://res.cloudinary.com/barkandbreakfast/image/upload/f_auto,q_auto/v1608506619/home3.jpg" alt=""/>
        </div>
      </Layout>
    )
  }
}
export default AboutPage

export const query = graphql`
  query {
    allAboutJson {
      nodes {
        id
        title
        description
        text
        subtitle
        name_icon
        title_icon
        mail_icon
        twitter_icon
        team_members {
          name
          title
          email
          twitter
          image
        }
      }
    }
  }
`
